<template>
  <div class="pbf">
     <BrandTabDiaries
        v-if="type == 'brands'"
        :inurl="'/diaries'"
        :brand-id="props.id"
        :category="props.type"
        :intags="tags"
      />
     <BrandTabDiaries
        v-else
        :inurl="'/diaries'"
        :product-id="props.id"
        :category="props.type"
        :intags="tags"
      />
  </div>
</template>



<script setup lang="ts">
 
import { TagsBrandProductDiaries } from '@/types/other'

interface Props {
  id: number
  type: string
}

const props = defineProps<Props>()

const tags = ref(structuredClone(TagsBrandProductDiaries))

</script>

<style scoped>

</style>